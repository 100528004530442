'use strict'

import Polyfills from './Polyfills.js'
import Bartender from '@fokke-/bartender.js'
import boringmenu from '@teppokoivula/boringmenu'
import Glightbox from 'glightbox'
import Swiper, { Autoplay, Navigation } from 'swiper'

/**
 * Site class contains general purpose site-specific features.
 *
 * @version 1.1.0
 */
export default class Site {
	/**
	 * Class constructor
	 *
	 * @param {Object} options Options for the class.
	 */
	constructor (options = {}) {
		this.options = {}

		// Init polyfills
		const polyfills = new Polyfills()
		polyfills.init()

		// Init Bartender after mobile menu is ready
		document.addEventListener('boringmenu-init-done', () => {
			window.bartender = new Bartender({
				debug: false,
				trapFocus: true,
				overlay: true,
			})

			window.addEventListener('bartender-open', (e) => {
				// When right bar is opened, focus to search query field
				if (e.detail.bar.position == 'right' && e.detail.button.classList.contains('mobile-search-btn')) {
					const searchQueryField = e.detail.bar.element.querySelector(
						'[data-bartender-bar="right"] .searchForm__input'
					)
					if (searchQueryField) searchQueryField.focus()
				}
			})
		})

		// Init mobile menu
		const mobileMenu = document.getElementById('mobile-menu')
		new boringmenu({
			selectors: {
				menu: '.menu-mobile__list--level-1',
			},
			classes: {
				item: 'menu-mobile__item',
				itemActive: 'menu-mobile__item--current',
				itemParent: 'menu-mobile__item--parent',
				toggle: 'menu-mobile__toggle',
				toggleTextContainer: 'sr-only',
			},
			labels: {
				'menu.open': mobileMenu
					? mobileMenu.getAttribute('data-labels-open')
					: 'open',
				'menu.close': mobileMenu
					? mobileMenu.getAttribute('data-labels-close')
					: 'close',
			},
			icons: {
				'menu.open': 'icon-open',
				'menu.close': 'icon-close',
			},
		})

		// Initialize
		this.init(options)
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @param {Object} options Options for the class.
	 * @return {Object}
	 */
	init (options = {}) {
		// Merge user options to the defaults
		this.options = {
			responsiveTables: {
				selector: 'main table',
			},
			imageLinks: {
				parentSelector: 'main',
			},
			...options,
		}

		// Call individual init methods
		this.initResponsiveTables()
		this.initSkipLinks()
		this.initImageLinks()
		this.initSwiper()
		this.initToggleSearch()
		this.initSmoothScroll()
		this.initPageSelectors()
		this.initFooterAccordion()
		this.initBartenderToggle()
		this.initScrolltoForm()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('site-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize responsive tables
	 *
	 * Finds content tables and wraps them with div.table-wrapper.
	 */
	initResponsiveTables () {
		document
			.querySelectorAll(this.options.responsiveTables.selector)
			.forEach((table) => {
				if (!table.closest('.table-wrapper')) {
					const tableWrapper = document.createElement('div')
					tableWrapper.classList.add('table-wrapper')
					tableWrapper.classList.add('overflow-x-auto')
					table.parentNode.insertBefore(tableWrapper, table)
					tableWrapper.appendChild(table)
				}
			})
	}

	/**
	 * Initialize skip links
	 *
	 * Finds skip links and enhances their behaviour for various screen readers and mobile devices.
	 */
	initSkipLinks () {
		const skipLinks = document.querySelectorAll(
			'.skip-link:not([data-skip-link])'
		)
		if (skipLinks.length) {
			const skipToBlur = (event) => {
				if (event.target.getAttribute('data-tabindex')) {
					event.target.removeAttribute('tabindex')
					event.target.removeAttribute('data-tabindex')
				}
			}
			skipLinks.forEach((skipLink) => {
				skipLink.setAttribute('data-skip-link', true)
				skipLink.addEventListener('click', (event) => {
					const skipTo = document.getElementById(
						event.target.href.split('#')[1]
					)
					if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
						event.preventDefault()
						skipTo.setAttribute('tabindex', '-1')
						skipTo.setAttribute('data-tabindex', true)
						skipTo.addEventListener('blur', skipToBlur)
						skipTo.addEventListener('focusout', skipToBlur)
						skipTo.focus()
					}
				})
			})
		}
	}

	/**
	 * Initialize image links
	 */
	initImageLinks () {
		// Parent of image links
		let parentNode = document.querySelector(
			this.options.imageLinks.parentSelector
		)
		if (!parentNode) return

		// Add glightbox class to image links
		parentNode
			.querySelectorAll(
				'a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]'
			)
			.forEach((link) => {
				if (!link.classList.contains('glightbox')) {
					if (
						!link.getAttribute('data-title') &&
						!link.getAttribute('data-glightbox')
					) {
						let figcaption = link.parentNode.querySelector('figcaption')
						if (figcaption) {
							let caption = figcaption ? figcaption.textContent : ''
							link.setAttribute('data-title', caption)
						}
					}
					link.classList.add('glightbox')
				}
			})

		// Initialize Glßightbox
		if (parentNode.querySelector('.glightbox')) {
			Glightbox()
		}
	}

	initSwiper () {
		const swiper = new Swiper('.swiper', {
			modules: [Navigation, Autoplay],
			// Optional parameters
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 24,
			watchOverflow: true,
			simulateTouch: true,
			rewind: true,
			centeredSlides: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
				clickable: true,
			},
		})
	}

	/**
	 * Init Toggle Search
	 */
	initToggleSearch () {
		const button = document.querySelector('.toggle-search')

		if (!button) return

		button.addEventListener('click', (e) => {
			e.preventDefault()
		})
	}

	/**
	 *
	 * Init Smooth Scroll behavior
	 */
	initSmoothScroll () {
		const links = document.querySelectorAll('[href^="#"]')

		if (!links) return

		for (const link of links) {
			link.addEventListener('click', (e) => {
				e.preventDefault()
				const target = document.querySelector(e.target.getAttribute('href'))
				if (!target) return

				scroll({
					top: target.offsetTop,
					behavior: 'smooth',
				})
			})
		}
	}

	initScrolltoForm () {
		window.addEventListener('formBuilderSubmitted', (e) => {
			window.setTimeout(() => {
				scroll({
					top: e.target.offsetTop,
					behavior: 'smooth',
				})
			}, 375)
		})
	}

	/**
	 *
	 * Init Page selectors
	 */
	initPageSelectors () {
		const items = document.querySelectorAll('.page-selector__container')
		if (!items.length) return

		for (const item of items) {
			const select = item.querySelector('.page-selector__select')
			const button = item.querySelector('[data-custom-dropdown-btn]')

			if (!select || !button) continue

			select.addEventListener('change', e => {
				button.click()
			})
		}
	}

	/**
	 *
	 * Init Footer Accordion
	 */
	initFooterAccordion () {
		const accordionHeaders = document.querySelectorAll(
			'[data-footer-accordion-header]'
		)
		if (!accordionHeaders.length) return

		// Add expand event to accordion buttons
		Array.prototype.forEach.call(accordionHeaders, (accordionHeader) => {
			let target = document.getElementById(
				accordionHeader.getAttribute('aria-controls')
			)
			if (target) {
				accordionHeader.onclick = () => {
					let expanded =
						accordionHeader.getAttribute('aria-expanded') == 'true' || false
					accordionHeader.setAttribute('aria-expanded', !expanded)
					target.classList.toggle('hidden', expanded)
				}
			}
		})
	}

	/**
	 *
	 * Init Bartender customisation for mobile meu.
	 */
	initBartenderToggle () {
		const bartender = document.querySelector('[data-bartender-bar=right]')
		if (!bartender) return

		let burgerBtn = document.querySelector('.hamburger-btn')
		let closeBtn = document.querySelector('.close-btn')

		window.addEventListener('bartender-open', (e) => {
			if (e.detail.bar.position == 'right') {
				closeBtn.classList.remove('hidden')
				burgerBtn.classList.add('hidden')
			}
		})

		window.addEventListener('bartender-close', (e) => {
			if (e.detail.bar.position == 'right') {
				closeBtn.classList.add('hidden')
				burgerBtn.classList.remove('hidden')
			}
		})
	}
}
